import React, { Fragment } from 'react';
// import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import '../styles.css';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
import classNames from 'classnames';
// import { Notifs } from 'redux-notifications';
import SidebarContainer from '@containers/SidebarContainer';
import TimelineContainer from '@containers/TimelineContainer';
// import DashboardContainer from '@containers/DashboardContainer';
import ToolbarLayerContainer from '@containers/ToolbarLayerContainer';
import ToolbarShapelayerContainer from '@containers/ToolbarShapelayerContainer';
import ToolbarTextContainer from '@containers/ToolbarTextContainer';
import ToolbarVideoContainer from '@containers/ToolbarVideoContainer';
import VideoControlsContainer from '@containers/VideoControlsContainer';
import VideoPreviewContainer from '@containers/VideoPreviewContainer';
// import PricingContainer from '@containers/PricingContainer';
import KeyboardShortcuts from '@components/KeyboardShortcuts';
// import IntercomContainer from '@containers/IntercomContainer';
import TrashArea from '@components/Timeline/Trash';
// import FullscreenLoader from '@components/FullscreenLoader';
// import ErrorBoundary from '@components/ErrorBoundary';
import { detect } from 'detect-browser';
// import { authService } from '@services/auth';
import 'redux-notifications/lib/styles.css';
import { sampleComposition } from '../mockdata';
import '../styles/theme-light.scss';
import './App.scss';
import BrowserInfo from './BroserDetector';
import Modals from './Modal/Modals';
import RenderEngine from "./Render/RenderEngine";
import Topbar from './TopHeader/Topbar';
import './Workspace.scss';


const propTypes = {
  sideMenuOpen: PropTypes.bool,
  firstProject: PropTypes.string,
  firstVideo: PropTypes.string,
  user: PropTypes.object,
  onUpdateUser: PropTypes.func.isRequired,
  onTogglePlay: PropTypes.func.isRequired,
  onRazorVideo: PropTypes.func.isRequired,
  notifyInfo: PropTypes.func.isRequired,
  onToggleTutorial: PropTypes.func.isRequired,
  saveComposition: PropTypes.func.isRequired,
  toggleUserAccountManager: PropTypes.func,
  theme: PropTypes.string.isRequired,
  emptyComposition: PropTypes.bool,
  dashboardView: PropTypes.bool,
  sendEvent: PropTypes.func,
  activeTextId: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  activeBackgroundId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activeLayerId: PropTypes.number,
  activeShape: PropTypes.number,
  onLoadCompositionObject: PropTypes.func,

};

class App extends React.Component {
  state = {};
  async fetchUser() {
    try {
      const userResponse = await window.sumocloud.getUser();
      this.props.onUpdateUser(userResponse)

    } catch (err) {
      console.error(err)
    }
  }
  componentDidMount() {
    window.loadComposition = this.props.onLoadCompositionObject;
    // const { user } = this.props;
    // const {
    //   hasSubscription, email, role, isTutorial, subscription, lastLogin,
    // } = user;

    // if (process.env.NODE_ENV === 'production' && !LogRocket._isInitialized) {
    //   LogRocket.init('2o2tnw/adlaunch');
    //   setupLogRocketReact(LogRocket);
    //   LogRocket.identify(user.uid, {
    //     email,
    //     role,
    //     subscription,
    //     isTutorial,
    //     lastLogin,
    //   });
    // }

    this.listener = window.addEventListener('keydown', (e) => {
      if (e.keyCode === 32 && e.target === document.body) {
        e.preventDefault();
      }
    });

    // Disable right click
    document.addEventListener('contextmenu', event => event.preventDefault());

    // Clear "clipboard"
    window.addEventListener('beforeunload', () => {
      window.localStorage.removeItem('copiedStyles');
    });

    this.setState({
      browserInfo: detect(),
    });
    this.fetchUser()


    // if (lastLogin === null) {
    //   this.props.sendEvent('User Started Trial', {
    //     userId: user.uid,
    //     traits: {
    //       email,
    //     },
    //   });
    // }

    // if (hasSubscription) {
    //   this.props.sendEvent('USER_SIGN_IN', {
    //     userId: user.uid,
    //     traits: {
    //       email,
    //       role,
    //       isTutorial,
    //       subscription,
    //       lastLogin,
    //     },
    //   });
    // }

    // check if auth token has expired
    // window.addEventListener('focus', () => {
    //   authService.isAuthenticated();
    // });

    setTimeout(() => {
      this.onCompositionStart()
    }, 1000)
  }
  async onCompositionStart() {
    const autosave = true ? null : window.localStorage.autosave;
    const params = new URLSearchParams(window.location.search);
    const workSlug = params.get("work");
    const isRendering = params.get("render");
    if (!isRendering) {
      if (workSlug) {
        // load work from sumo site
        const workResponse = await window.sumocloud.getWorksData({ slug: workSlug }, (p) => {
          console.log(p)
        });
        this.props.onLoadCompositionObject(workResponse.data.data);
      }
      else {
        // open default project
        this.props.onLoadCompositionObject(autosave ? JSON.parse(autosave) : sampleComposition);
      }
    } else {
      // uncomment to test video engine - localhost:3002/?render=1
      // const workResponse = await window.sumocloud.getWorksData({ slug: workSlug }, (p) => {
      //   console.log(p)
      // });
      // window.loadComposition(workResponse.data.data)
    }

  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.listener);
  }

  render() {
    // const isMobile = browserInfo.os === 'iOS' || browserInfo.os === 'Android OS';
    const {
      sideMenuOpen,
      // user,
      onTogglePlay,
      onRazorVideo,
      notifyInfo,
      theme,
      activeTextId,
      activeBackgroundId,
      activeLayerId,
      activeShape,
      // emptyComposition,
      // dashboardView,
    } = this.props;

    // const { uid, hasSubscription } = user;

    const shortcuts = [
      {
        keys: 'space',
        fn: onTogglePlay,
      },
      {
        keys: 'c',
        fn: onRazorVideo,
      },
    ];

    const classes = classNames('App__wrapper', {
      'App__wrapper--toggle': sideMenuOpen,
    });

    // if (!uid) {
    //   return <FullscreenLoader />;
    // }

    // if (!hasSubscription) {
    //   return (
    //     <div className="App__payment--gateway">
    //       <div
    //         className="backdrop"
    //         style={{
    //           filter: !hasSubscription && 'blur(4px) brightness(75%)',
    //           background:
    //             'url("https://adlaunch-public.s3-eu-west-1.amazonaws.com/images/login_background_clear.jpg")',
    //         }}
    //       ></div>
    //       <PricingContainer canceledSubscription={true} />
    //     </div>
    //   );
    // }
    // TODO: implement mobile view
    // if (isMobile) {
    //   // mobile page goes here.
    //   return <div className='mobile'>This will be the mobile page</div>;
    // }
    const params = new URLSearchParams(window.location.search);
    const isRendering = params.get("render");
    if (!!isRendering) return (
      <RenderEngine />
    )
    return (
      <DndProvider backend={Backend}>
        <Fragment>
          <div className={`App ${theme}`}>
            {/* <ErrorBoundary> */}
            {/* <Notifs /> */}
            <BrowserInfo browserInfo={this.state.browserInfo} notifyInfo={notifyInfo} />
            <KeyboardShortcuts shortcuts={shortcuts} />
            {/* <SidemenuContainer /> */}
            <div className={classes}>
              {/* <TopHeaderContainer /> */}
              <Topbar />
              <Modals />
              <div className="App__mid-container">
                <Fragment>
                  <SidebarContainer />
                  <div className="Workspace">
                    <div className="Toolbar">
                      {activeTextId && <ToolbarTextContainer />}
                      {activeBackgroundId && <ToolbarVideoContainer />}
                      {activeLayerId && <ToolbarLayerContainer />}
                      {activeShape && <ToolbarShapelayerContainer />}
                    </div>
                    <VideoPreviewContainer />
                    {/* <PendingRenders /> */}
                    <TrashArea />
                    <VideoControlsContainer />
                  </div>
                </Fragment>
              </div>
              <TimelineContainer />
            </div>

            {/* <div className="App__mid-container">
                  {dashboardView ? (
                    <DashboardContainer />
                  ) : (
                    <Fragment>
                      <SidebarContainer />
                      <div className="Workspace">
                        <div className="Toolbar">
                          {activeTextId && <ToolbarTextContainer />}
                          {activeBackgroundId && <ToolbarVideoContainer />}
                          {activeLayerId && <ToolbarLayerContainer />}
                          {activeShape && <ToolbarShapelayerContainer />}
                        </div>
                        <VideoPreviewContainer />
                        <TrashArea />
                        <VideoControlsContainer />
                      </div>
                    </Fragment>
                  )}
                </div>
                {!(emptyComposition && dashboardView) && <TimelineContainer />}
              </div> */}
            {/* </ErrorBoundary> */}
          </div>
        </Fragment>
      </DndProvider>
    );
  }
}

App.propTypes = propTypes;

export default App;
