import { appToggleChat, appToggleSideMenu, doRedo, doUndo } from '@actions/app';
import { compositionReset, loadCompositionObject } from '@actions/composition';
import { toggleDashboard } from '@actions/dashboard';
import { toggleModal } from '@actions/modals';
import { notifyInfo } from '@actions/notifs';
import { sidebarTabToggle } from '@actions/sidebar';
import { timelineToggleFullscreen } from '@actions/timeline';
import { receiveUser, resetUser } from '@actions/user';
import { NavBar } from '@sumo-apps/core';
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { askForFile } from '../../lib/file';
import { extract } from '../../lib/zip';

const getBreadCrumbs = (projects, composition, activeProjectUid) => {
    let string = '';

    projects.entries.forEach((project) => {
        if (project.uid !== activeProjectUid) return string;

        string += project.name;

        project.videos.forEach((video) => {
            if (video.uid === composition.uid) {
                string += ` / ${video.name}`;
            }
        });
        return null;
    });

    return string;
};
function Topbar({
    onUndo,
    onRedo,
    canUndo,
    canRedo,
    onToggleModal,
    strings,
    onLoadCompositionObject,
    onCompositionReset,
    user,
    onUpdateUser,
    onResetUser
}) {
    function handleNewComposition() {
        console.log("new file")
        onToggleModal({
            confirm: true,
            data: {
                title: "Are you sure?",
                btnText1: "cancel",
                btnAction1: () => onToggleModal({ confirm: false, data: null }),
                btnText2: "ok",
                btnAction2: () => {
                    onCompositionReset()
                    onToggleModal({ confirm: false, data: null })
                }
            }
        })
    }
    async function handleOpenProject() {
        try {
            const files = await askForFile();
            const response = await extract(files[0]);
            console.log({ response }, response[0])
            onLoadCompositionObject(response[0])
        } catch (err) {
            alert("Could not open Sumo file." + err)
        }


    }
    const menuConfig = [
        {
            name: strings._file,
            items: [
                {
                    name: strings.new,
                    shortcut: 'Cmd-N',
                    disabled: false,
                    func: () => {
                        // sendDataLayer({
                        //   category: 'navbar',
                        //   action: 'file-new'
                        // })
                        handleNewComposition()
                    }
                },
                {
                    name: strings._open,
                    disabled: false,
                    shortcut: 'Cmd-O',
                    func: () => {
                        // sendDataLayer({
                        //   category: 'navbar',
                        //   action: 'file-open-fromhd'
                        // })
                        handleOpenProject()
                    }
                },
                {
                    name: strings._openfromcloud,
                    id: 'openFromCloud',
                    shortcut: 'Cmd-Shift-O',
                    disabled: false,
                    func: () => {
                        // sendDataLayer({
                        //   category: 'navbar',
                        //   action: 'file-open-fromcloud'
                        // })
                        // document.querySelector('.library .sumo-square-icon').click()
                    }
                },
                {
                    name: null // null appears as divider
                },
                {
                    name: strings._save,
                    disabled: false,
                    shortcut: 'Cmd-S',
                    func: () => {
                        // sendDataLayer({
                        //   category: 'navbar',
                        //   action: 'file-save-tohd'
                        // })
                        onToggleModal({
                            saveProject: true
                        })
                    }
                },
                {
                    name: strings._savetocloud,
                    id: 'saveToCloud',
                    shortcut: 'Cmd-Shift-S',
                    disabled: false,
                    func: () => {
                        // sendDataLayer({
                        //   category: 'navbar',
                        //   action: 'file-save-tocloud'
                        // })
                        // handleSaveToCloud()
                        onToggleModal({
                            saveToCloud: true
                        })
                    }
                },
                {
                    name: strings._export,
                    id: 'export',
                    shortcut: 'Cmd-Shift-E',
                    disabled: false,
                    func: async () => {
                        onToggleModal({
                            exportVideo: true
                        })
                    }
                },
                {
                    name: null // null appears as divider
                },
                {
                    name: strings._exit,
                    func: () => {
                        // sendDataLayer({
                        //   category: 'navbar',
                        //   action: 'file-exit'
                        // })
                        const queryString = window.location.search
                        const urlParams = new URLSearchParams(queryString)
                        const modeParameter = urlParams.get('mode')
                        if (modeParameter === 'suite') {
                            window.parent.postMessage({ request: 'Close', appName: 'pixel' }, '*')
                        } else {
                            window.location.href = '/' //window.location.href = 'https://sumo.app'
                        }
                    }
                },
            ]
        },
        {
            name: strings._edit,
            items: [
                {
                    name: strings._undo,
                    shortcut: 'Cmd-Z',
                    disabled: !canUndo,
                    func: onUndo,
                },
                {
                    name: strings._redo,
                    shortcut: 'Cmd-Y',
                    disabled: !canRedo,
                    func: onRedo,
                },
                {
                    name: strings._copy,
                    disabled: false,
                    shortcut: 'Cmd-C',
                    func: () => {
                        // sendDataLayer({
                        //   category: 'navbar',
                        //   action: 'edit-copy'
                        // })
                        // handleCopy()
                    }
                },
                {
                    name: strings._paste,
                    disabled: false,
                    shortcut: 'Cmd-P',
                    func: () => {
                        // sendDataLayer({
                        //   category: 'navbar',
                        //   action: 'edit-paste'
                        // })
                        // handlePaste()
                    }
                }
            ]
        },
        {
            name: strings._help,
            items: [
                // {
                //     name: 'Tutorial',
                //     disabled: false,
                //     func: () => { }
                // },
                {
                    name: strings._support,
                    disabled: false,
                    func: () => {
                        // sendDataLayer({
                        //   category: 'navbar',
                        //   action: 'help-support'
                        // })
                        window.open('https://sumo.app/support', "_blank")
                    }
                },
                {
                    name: null
                },
                {
                    name: strings._about,
                    disabled: false,
                    func: () => {
                        onToggleModal({
                            about: true
                        })
                        // sendDataLayer({
                        //   category: 'navbar',
                        //   action: 'help-about'
                        // })
                        // showAboutModal()
                    }
                },
            ]
        }
    ]
    const loggedIn = useMemo(() => !!user.slug, [user]);
    return (
        <NavBar
            title="video"
            color="#C62E2D"
            languages={['ar', 'bn', 'cs', 'da', 'de', 'el', 'en', 'es', 'et', 'fa', 'fi', 'fr', 'hi', 'hu', 'id', 'it', 'ja', 'ko', 'ms', 'nl', 'no', 'pl', 'pt', 'ru', 'sv', 'th', 'tl', 'tr', 'vi', 'zh']}
            translations={strings}
            onMenuChange={(menuState) => {
                if (menuState.Library === true) {
                    // setCloudProps(prevState => ({
                    //   ...prevState,
                    //   libraryOpen: true
                    // }))
                } else if (menuState.Library === false) {
                    // setCloudProps(prevState => ({
                    //   ...prevState,
                    //   libraryOpen: false
                    // }))
                }
            }}
            trigger="hover"
            onAppChange={(url) => (window.location.href = url.split('?')[0])}
            undoRedo={{
                onUndo: onUndo,
                onRedo: onRedo,
                hasPast: canUndo,
                hasFuture: canRedo
            }}
            library={{
                open: false,
                enabled: true
            }}
            user={{
                info: user,
                loggedIn: loggedIn,
                onLogin: (data) => {
                    onUpdateUser(data)
                },
                onLogout: (data) => {
                    onResetUser()
                },
                onClick: (payload) => {
                    console.log({ payload })
                }
            }}
            mobile={false}
            menuConfig={menuConfig}
        />
    )
}

const mapStateToProps = (state) => {
    const {
        projects,
        sidebar,
        dashboard,
        languages,
        timeline,
        user,
    } = state;
    const composition = state.composition.present;
    const breadCrumbs = getBreadCrumbs(projects, composition, sidebar.activeProjectUid);

    return {
        breadCrumbs,
        composition,
        strings: languages.strings,
        saving: state.app.saving,
        canUndo: state.composition.past && state.composition.past.length > 1,
        canRedo: state.composition.future && state.composition.future.length > 0,
        projectsOpen: dashboard.dashboardView,
        currentTime: timeline.currentTime,
        user,
    };
};

export default connect(mapStateToProps, {
    onToggleModal: toggleModal,
    onUndo: doUndo,
    onRedo: doRedo,
    onLoadCompositionObject: loadCompositionObject,
    onToggleFullscreen: timelineToggleFullscreen,
    onToggleChat: appToggleChat,
    onToggleHomePage: toggleDashboard,
    onToggleSideMenu: appToggleSideMenu,
    onCompositionReset: compositionReset,
    onUpdateUser: receiveUser,
    onResetUser: resetUser,
    notifyInfo,
    sidebarTabReset: sidebarTabToggle,
})(Topbar);
