import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const ColorSwatches = ({ currentSettings, onSidebarAdjusters }) => {
  const [pickedColorIndex, setPickedColor] = useState();
  return currentSettings.map((childSetting) => {
    const { attr, index, type } = childSetting;
    if (!attr.fill) return null;
    return (
      <div key={index + type}>
        <div
          className="svgColorSwatch"

          style={{
            borderColor: pickedColorIndex === index ? 'black' : '#ccc',
            backgroundColor: attr.fill,
          }}
          onClick={() => {
            setPickedColor(index);
            onSidebarAdjusters({
              label: 'svgColors',
              index,
              fill: attr.fill,
            });
          }}
        />
      </div>
    );
  });
};

ColorSwatches.propTypes = {
  currentSettings: PropTypes.array.isRequired,
  type: PropTypes.string,
  toggleColorPicker: PropTypes.func,
};

export default ColorSwatches;
