import React from 'react';
// import PropTypes from 'prop-types';
import { compositionVideoInstanceSplit, loadCompositionObject, saveComposition } from '@actions/composition';
import { notifyInfo } from '@actions/notifs';
import { timelineTogglePlay } from '@actions/timeline';
import { toggleTutorial } from '@actions/tutorial';
import { receiveUser } from '@actions/user';
import App from '@components/App';
import FullscreenLoader from '@components/FullscreenLoader';
import { loadWebFonts } from '@lib/webFonts';
import { connect } from 'react-redux';
// import { pageLoad, sendEvent } from '@actions/analytics';

const propTypes = {
  // onLoad: PropTypes.func.isRequired,
  // analyticsPageLoad: PropTypes.func.isRequired,
  // user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    app, user, sidebar, timeline, dashboard,
  } = state;
  const composition = state.composition.present;
  const checkActiveText = () => (composition.textInstances.length > 0 && composition.titleInstances
    .find(titles => titles.visibleFrom <= timeline.currentTime &&
      titles.visibleTo >= timeline.currentTime));
  const activeText = (composition.textInstances && composition.textInstances
    .find(instance => instance.id === sidebar.activeTextId)) ||
    (checkActiveText() && checkActiveText().textInstances[0]);
  const {
    activeBackgroundId, activeLayerId, activeTextId, selectedShapes,
  } = sidebar;

  return {
    sideMenuOpen: app.sideMenuOpen,
    userAccountManagerVisible: app.userAccountManagerVisible,
    user,
    activeText,
    activeTextId,
    activeBackgroundId,
    activeLayerId,
    activeShape: selectedShapes[0],
    emptyComposition: state.composition.present.uid === null,
    theme: app.lightTheme ? 'light' : 'dark',
    dashboardView: dashboard.dashboardView,
  };
};

class AppContainer extends React.Component {
  state = {
    fontsLoaded: false,
  };

  async componentDidMount() {
    // const { onLoad, analyticsPageLoad, user } = this.props;
    // if (!user.uid) await onLoad();
    // analyticsPageLoad('Editor');
    // TODO: handle font loading error
    const fontsLoaded = await loadWebFonts();
    this.setState({
      fontsLoaded,
    });

    window.dispatchEvent(new Event('resize'));
    // Fix when know why fails in production
    // Sentry.init({
    //   dsn: 'https://64c6269c07414ffcbd65c770f4c994ae@o146526.ingest.sentry.io/1191444',
    //   release: `editor@${process.env.npm_package_version}`,
    //   integrations: [new Integrations.BrowserTracing()],
    //   tracesSampleRate: 0.25, // Be sure to lower this in production
    // });
  }

  render() {
    // const { user } = this.props;
    const { fontsLoaded } = this.state;
    const isLoading = !fontsLoaded; // !user.uid && !fontsLoaded;

    if (isLoading) return <FullscreenLoader />;

    return <App {...this.props} />;
  }
}

AppContainer.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {
    // onLoad: fetchUser,
    // analyticsPageLoad: pageLoad,
    notifyInfo,
    onUpdateUser: receiveUser,
    onToggleTutorial: toggleTutorial,
    onTogglePlay: timelineTogglePlay,
    onRazorVideo: compositionVideoInstanceSplit,
    saveComposition,
    onLoadCompositionObject: loadCompositionObject
    // sendEvent,
  },
)(AppContainer);
