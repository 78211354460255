/* eslint-disable no-unused-vars */
import { getUniqueId, getVideoCover } from '@helpers/footage';
import { nanoid } from 'nanoid'
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
// import { FILESTACK_PUBLIC_API_KEY } from '@config';
// import ReactFilestack from 'filestack-react';
import Button from '@components/Button';
import ConfirmDialog from '@components/ConfirmDialog';
import InfiniteScroll from '@components/InfiniteScroll';
import Spinner from '@components/Spinner';
import classNames from 'classnames';
import { useDispatch } from "react-redux";
import { updateApp } from "../../actions/app";
import { askForFile } from '../../lib/file';
import Uploads from './Uploads';
import VideoElements from './VideoElements';
import './VideosTab.scss';


const defaultProps = {
  footage: [],
  filter: 'stock',
};

const propTypes = {
  footagePages: PropTypes.array,
  onFilterToggle: PropTypes.func.isRequired,
  onUploadsFilterToggle: PropTypes.func,
  onCategoriesToggle: PropTypes.func,
  filter: PropTypes.string,
  showCategories: PropTypes.bool,
  onLoad: PropTypes.func,
  onSearch: PropTypes.func,
  onSearchTags: PropTypes.func,
  onClearTagsSearch: PropTypes.func,
  searchSuggestions: PropTypes.array,
  loading: PropTypes.bool,
  currentPage: PropTypes.number,
  searchTerm: PropTypes.string,
  onUpload: PropTypes.func,
  activeUploads: PropTypes.array,
  onAdd: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onRemoveUploadVideo: PropTypes.func,
  onRemoveFromFavorite: PropTypes.func,
  composition: PropTypes.object,
  onChangeSorting: PropTypes.func,
  expandPool: PropTypes.func,
  onProgressJump: PropTypes.func,
  sorting: PropTypes.string,
  relatedTags: PropTypes.array,
  onAddToFavorite: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onShowContext: PropTypes.func,
  filterButtons: PropTypes.array,
  footageCategories: PropTypes.array,
  backgroundInstances: PropTypes.array,
  formatBackgroundInstances: PropTypes.func,
};

const VideosTab = (props) => {
  const [showTerms, setShowTerms] = useState(false);
  const [searchTerm, setSearchTerm] = useState('*');
  const [warning, setWarning] = useState({ visible: false, uid: null });
  const [showScroll, setShowScroll] = useState(false);
  const {
    footagePages,
    onSelect,
    onLoad,
    onSearch,
    filter,
    showCategories,
    onFilterToggle,
    onUploadsFilterToggle,
    onCategoriesToggle,
    loading,
    onUpload,
    activeUploads,
    onSearchTags,
    stockVideoLoaded,
    onClearTagsSearch,
    searchSuggestions,
    onChangeSorting,
    sorting,
    relatedTags,
    onAdd,
    handleAdd,
    onCancel,
    onRemoveUploadVideo,
    composition,
    onAddToFavorite,
    onProgressJump,
    onRemoveFromFavorite,
    onShowContext,
    filterButtons,
    updateFootage,
    footageCategories,
    backgroundInstances,
    formatBackgroundInstances,
    expandPool,
  } = props;

  const scrollToTop = () => {
    const list = document.querySelector('.VideosTab__list');
    if (list) {
      list.scrollTo(0, 0);
    }
  };
  const debouncedSearch = onSearch && debounce(onSearch, 500);
  const debouncedTagSearch = onSearchTags && debounce(onSearchTags, 500);
  const dispatch = useDispatch();
  const handleFilterToggle = (selectedFilter) => {
    if (selectedFilter === 'image' || selectedFilter === 'video' || selectedFilter === 'logo') {
      onUploadsFilterToggle(selectedFilter);
    } else {
      onFilterToggle('videoFootageFilter', selectedFilter);
    }
    scrollToTop();
    if (selectedFilter !== 'stock') onLoad(selectedFilter);
  };

  const handleLoad = (page) => {
    // Prevent loading next page of data when
    // search results are visible;
    return
    // if (filter === 'stock') {
    //   debouncedSearch('video', searchTerm, filter, null, sorting, page + 1);
    // } else {
    //   onLoad(filter, page);
    // }
  };

  const handleReset = () => {
    onSearch('video', '*', filter, false, sorting);
  };


  const handleSearch = (query) => {
    setSearchTerm(query);
    debouncedSearch('video', query, filter, null, sorting);
    scrollToTop();
  };

  const handleScroll = (e) => {
    const element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      setShowScroll(false);
    } else {
      setShowScroll(true);
    }
  };

  const handleCategoryClick = (categoryName) => {
    onSearch('video', '*', filter, categoryName, sorting);
    scrollToTop();
  };

  const categoryList = footageCategories && footageCategories.map((cat, index) => (
    <li
      key={index}
      className="VideoTab__Categories__Item"
      onClick={() => {
        onCategoriesToggle();
        handleCategoryClick(cat);
      }}
    >
      {cat}
    </li>
  ));
  useEffect(() => {
    if (!stockVideoLoaded) {
      fetchVideosFromCMS()
    }
    return () => {
      dispatch(updateApp({
        stockVideoLoaded: true
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockVideoLoaded])
  async function fetchVideosFromCMS() {
    const response = await fetch("https://cms.sumo.app/api/projects/admins/pages?template=sample-asset");
    const data = await response.json();
    for (const media of data.data.filter(media => media.data.asset.type.includes("video"))) {
      // const hasThumbnail = media.data.asset.thumb && media.data.asset.thumb.length > 5;
      // let videoThumb = hasThumbnail ? media.data.asset.thumb : null;
      // console.log({ hasThumbnail }, media.data.asset.thumb)
      // if (!hasThumbnail) {
      const videoResponse = await fetch(media.data.asset.url);
      const blob = await videoResponse.blob();
      const videoBlob = await getVideoCover(blob);
      const videoThumb = URL.createObjectURL(videoBlob);
      // }
      updateFootage({
        id: media.data.asset.id,
        src: media.data.asset.url,
        top: 0,
        left: 0,
        type: "video",
        videoId: media.data.asset.id,
        duration: 2057,
        playFrom: 0,
        width: 1920,
        height: 1080,
        thumbnail: {
          hd: videoThumb,
          nano: videoThumb,
          video: videoThumb
        },
        visibleTo: 2057,
        aspectRatio: "16:9",
        visibleFrom: 0,
        aspectNumber: 1.778,
        sourceDuration: 22000,
        thumbnailImageSrc: videoThumb
      }, "stockVideos")
    };
    dispatch(updateApp({
      stockVideoLoaded: true
    }))
  }
  const relatedTagsItems =
    relatedTags &&
    relatedTags.map((tag, index) => (
      <li
        key={index}
        className="VideosTab__RelatedTags__Item"
        onClick={() => {
          scrollToTop();
          onSearch('video', tag, filter, false, sorting);
        }}
      >
        {tag}
      </li>
    ));

  const spinnerClasses = classNames('Curtain', {
    'Curtain--loading': loading,
  });

  const confirmVideoDeletion = (uid) => {
    setWarning({
      visible: true,
      uid,
    });
  };

  const filestackOptions = {
    fromSources: [
      'local_file_system',
      'url',
      'googledrive',
      'dropbox',
      'onedrive',
    ],
    accept: ['video/*'],
    maxFiles: 10,
    maxSize: 262144000, // 250Mb (250 * 1024 * 1024)
    storeTo: {
      path: 'video/',
    },
  };
  const videoElements = <VideoElements
    videoPages={footagePages}
    onSelect={onSelect}
    onShowContext={onShowContext}
    key={getUniqueId()}
    filter={filter}
    onAdd={onAdd}
    expandPool={expandPool}
    handleAdd={handleAdd}
    onProgressJump={onProgressJump}
    onCancel={onCancel}
    onRemoveUploadVideo={confirmVideoDeletion}
    onRemoveFromFavorite={onRemoveFromFavorite}
    backgroundInstances={backgroundInstances}
    composition={composition}
    onAddToFavorite={filter === 'stock' && onAddToFavorite}
    formatBackgroundInstances={formatBackgroundInstances}
  />;

  async function handleVideoUpload() {
    try {
      const files = await askForFile(".mp4");
      let reader = new FileReader()
      const videoBlob = await getVideoCover(files[0]);
      const videoThumb = URL.createObjectURL(videoBlob);
      reader.onload = (e) => {
        let mediaSrc = e.target.result
        if (e.target.type === 'video' && e.target.result.includes('data:video/quicktime')) {
          mediaSrc = mediaSrc.replace('data:video/quicktime', 'data:video/mp4')
        }
        const obj = {
          id: nanoid(),
          src: mediaSrc,
          top: 0,
          left: 0,
          type: "video",
          videoId: "4285901",
          duration: 2057,
          playFrom: 0,
          thumbnail: {
            hd: videoThumb,
            nano: videoThumb,
            video: videoThumb
          },
          visibleTo: 2057,
          aspectRatio: "16:9",
          visibleFrom: 0,
          aspectNumber: 1.778,
          sourceDuration: 22000,
          thumbnailImageSrc: ""
        }
        updateFootage(obj, "stockVideos")

        console.log({ obj })
      }
      reader.readAsDataURL(files[0])
    } catch (err) {
      alert("Could not open Sumo file." + err)
    }
  }
  return (
    <div className="VideosTab">
      <div className='upload-asset-wrapper'>
        <button className='sumo-btn sumo-btn-video sumo-btn-colored' onClick={handleVideoUpload}>
          Upload video
        </button>
        <p>or drag and drop here</p>
      </div>
      {/*<section className="VideosTab__heading--section VideosTabHeading">
         <ButtonGroup items={filterButtons} selected={filter} onSelect={handleFilterToggle} /> */}
      {/* {(filter === 'upload' || filter === 'video') && <ReactFilestack
          apikey={FILESTACK_PUBLIC_API_KEY}
          options={filestackOptions}
          onSuccess={onUpload}
          render={({ onPick }) => (
            <Button className="VideosTab__upload-btn" color="pink" onClick={onPick} withIcon={true}>
              Upload video
              <UploadCloud />
            </Button>
          )}
        />} */}
      {/* { (filter !== 'uploads' && filter !== 'video') && (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '90%' }}>
            <Search
              suggestions={searchSuggestions}
              onSearch={debouncedTagSearch}
              onSelect={handleSearch}
              onReset={handleReset}
              onClear={onClearTagsSearch}
              sorting={sorting}
              onChangeSorting={onChangeSorting}
              filter={filter}
              showCategories={showCategories}
              onCategoriesToggle={onCategoriesToggle}
              className="mt(0.5)"
              placeholder="Search from Shutterstock"
            />
          </div>
          <div className="VideosTab__heading--tos">
            {filter === 'stock' &&
              <span style={{ position: 'relative' }} onMouseEnter={() => setShowTerms(true)} onMouseLeave={() => setShowTerms(false)}>
                <Info />
              </span>}
              {showTerms && <Terms />}
          </div>
        </div>)} */}
      {/* <div className="ContextSettingsGrid__Row">
          <div className="VideosTab__Tag--section">
          {showCategories && (
            <div className="VideosTab__Categories">
              <ul>{categoryList}</ul>
            </div>
          )
            // TODO: Move related tags to better place like under video clips
            // : (<div className="VideosTab__RelatedTags">
            //   <ul>{relatedTagsItems}</ul>
            // </div>)
          }
          </div>
        </div> 
      </section>*/}
      <section className="VideosTab__List--section">
        {(loading || !stockVideoLoaded) && (
          <div className={spinnerClasses}>
            <Spinner visible={true} />
          </div>
        )}

        {!showCategories && (
          <Fragment>

            <InfiniteScroll
              className="scroll-y VideosTab__list"
              onScroll={handleScroll}
              showScroll={showScroll}
              onLoadMore={handleLoad}
              page={footagePages.length}
            >
              {(filter !== 'stock' && filter !== 'favorite') &&
                <Uploads onUpload={onUpload} activeUploads={activeUploads} />}
              {/* render videoElements */}
              {videoElements}
            </InfiniteScroll>
            <ConfirmDialog
              visible={warning.visible}
              title={'Confirm deletion'}
              onClose={() => setWarning({ visible: false, uid: null })}
              cancelButton={
                <Button onClick={() => setWarning({ visible: false, uid: null })}>Cancel</Button>
              }
              confirmButton={
                <Button color="red" onClick={() => {
                  onRemoveUploadVideo(warning.uid);
                  setWarning({ visible: false, uid: null });
                }}>
                  Yes, delete my uploaded video.
                </Button>
              }
            >
              This action will delete your uploaded video. Undoing is not possible!
            </ConfirmDialog>
          </Fragment>
        )}
      </section>
    </div>
  );
};

VideosTab.propTypes = propTypes;
VideosTab.defaultProps = defaultProps;

export default VideosTab;
