export const sampleComposition = {
	uid: "c3afb6c3-01ee-4ab7-8f6c-2d92053edb21",
	// compositionVersion: 2,
	width: 1920,
	height: 1080,
	layers: [],
	duration: 12000,
	textInstances: [
		{
			id: 1558435666260,
			top: 540,
			fill: "#ffffff",
			left: 960,
			overflowWrap: "normal",
			lineHeight: "normal",
			whiteSpace: "normal",
			type: "text",
			ang: 0,
			track: 2,
			text: "Video editor",
			width: 1412,
			height: 226,
			scaleX: 1,
			scaleY: 1,
			styles: {},
			duration: 12000,
			fontSize: 200,
			resizing: false,
			fontStyle: "normal",
			textAlign: "center",
			visibleTo: 12000,
			fontFamily: "Quicksand",
			fontWeight: "bold",
			visibleFrom: 1,
			backgroundColor: "rgba(0,0,0,0)"
		}
	],
	audioInstances: [],
	shapeInstances: [],
	titleInstances: [
		{
			visibleFrom: 1,
			visibleTo: 15000,
			textInstances: [
				{
					id: 1558435666260,
					top: 540,
					fill: "#ffffff",
					left: 960,
					overflowWrap: "normal",
					lineHeight: "normal",
					whiteSpace: "normal",
					type: "text",
					ang: 0,
					track: 2,
					text: "Video editor",
					width: 1412,
					height: 226,
					scaleX: 1,
					scaleY: 1,
					styles: {},
					duration: 12000,
					fontSize: 200,
					resizing: false,
					fontStyle: "normal",
					textAlign: "center",
					visibleTo: 12000,
					fontFamily: "Quicksand",
					fontWeight: "bold",
					visibleFrom: 1,
					backgroundColor: "rgba(0,0,0,0)"
				}
			]
		}
	],
	backgroundInstances: [
		{
			id: 1558435543469,
			src: "https://cdn.siteadmin.app/projects/1818cd46-95dd-4204-8550-af77b11c91b2/assets/paint-layers-1.mp4",
			top: 0,
			left: 0,
			type: "video",
			videoId: "399cc47c28eb491b8f42892676d25f014526c128",
			duration: 12000,
			playFrom: 1,
			thumbnail: {
				hd: "https://d128k4sqj0do41.cloudfront.net/306a8649-d632-474d-8270-1da58c950a1b/images/home-section-2.png",
				nano: "https://d128k4sqj0do41.cloudfront.net/306a8649-d632-474d-8270-1da58c950a1b/images/home-section-2.png",
				video: "https://d128k4sqj0do41.cloudfront.net/306a8649-d632-474d-8270-1da58c950a1b/images/home-section-2.png"
			},
			visibleTo: 12000,
			visibleFrom: 1,
			sourceDuration: 12000,
		},
		{
			"id": 1593527780220851500,
			"src": "https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjM4NjEyfQ",
			"top": -100.15429122468665,
			"left": 0,
			"maxX": 0,
			"maxY": 0,
			"minX": 0,
			"minY": -200.3085824493733,
			"type": "image",
			"color": "#94694D",
			"isFav": true,
			"likes": 3050,
			"lockX": true,
			"lockY": false,
			"width": 2074,
			"author": {
				"id": "73nBk44oqBE",
				"bio": null,
				"name": "Mayur Gala",
				"links": {
					"html": "https://unsplash.com/@mayurgala",
					"self": "https://api.unsplash.com/users/mayurgala",
					"likes": "https://api.unsplash.com/users/mayurgala/likes",
					"photos": "https://api.unsplash.com/users/mayurgala/photos",
					"followers": "https://api.unsplash.com/users/mayurgala/followers",
					"following": "https://api.unsplash.com/users/mayurgala/following",
					"portfolio": "https://api.unsplash.com/users/mayurgala/portfolio"
				},
				"location": null,
				"username": "mayurgala",
				"last_name": "Gala",
				"first_name": "Mayur",
				"updated_at": "2019-11-29T17:30:26-05:00",
				"total_likes": 0,
				"accepted_tos": false,
				"total_photos": 1,
				"portfolio_url": "http://500px.com/iDeViL17",
				"profile_image": {
					"large": "https://images.unsplash.com/placeholder-avatars/extra-large.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=128&w=128",
					"small": "https://images.unsplash.com/placeholder-avatars/extra-large.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=32&w=32",
					"medium": "https://images.unsplash.com/placeholder-avatars/extra-large.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&cs=tinysrgb&fit=crop&h=64&w=64"
				},
				"twitter_username": null,
				"total_collections": 0,
				"instagram_username": null
			},
			"height": 1383,
			"scaleX": 0.9257473481195756,
			"scaleY": 0.9257473481195756,
			"duration": 1351,
			"kenburns": true,
			"playFrom": 0,
			"renderSrc": "https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjM4NjEyfQ",
			"thumbnail": {
				"hd": "https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjM4NjEyfQ",
				"nano": "https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&ixid=eyJhcHBfaWQiOjM4NjEyfQ",
				"small": "https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjM4NjEyfQ"
			},
			"visibleTo": 7350,
			"favoriteId": "f79d1a18-c69e-4e18-82e1-a52a801b1d42",
			"zoomEffect": {
				"X": 8,
				"Y": 4,
				"scale": 1.04
			},
			"visibleFrom": 5999,
			"scaleToWidth": true,
			"lockMovementX": true,
			"lockMovementY": false,
			"scaleToHeight": true,
			"thumbnailImageSrc": "https://images.unsplash.com/reserve/Af0sF2OS5S5gatqrKzVP_Silhoutte.jpg?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjM4NjEyfQ"
		},
	]
}
export const emptyComposition = {
	uid: "empty-composition",
	// compositionVersion: 2,
	width: 1920,
	height: 1080,
	layers: [],
	duration: 12000,
	textInstances: [],
	audioInstances: [],
	shapeInstances: [],
	titleInstances: [],
	backgroundInstances: []
}