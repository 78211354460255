import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '@components/Button';
import { Pause, Play } from '@components/Icons';

import './TrackRow.scss';

const propTypes = {
  track: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  timelineTogglePlay: PropTypes.func,
  active: PropTypes.bool,
  playing: PropTypes.bool,
  onPlay: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
};

const defaultProps = {
  active: false,
  playing: false,
};

class TrackRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.audioElement = React.createRef();
  }
  state = {
    playReady: false,
    duration: 0
  }
  toggleSelect = (e) => {
    const {
      onSelect,
      track,
      // timelineTogglePlay,
      // playing,
    } = this.props;
    // if (playing) timelineTogglePlay();
    this.props.onPause();
    onSelect(e, track);
  };

  handlePlay = (e) => {
    e.stopPropagation();
    const { track } = this.props;
    this.props.onPlay(e, track.uid);
  }

  handlePause = () => {
    this.props.onPause();
  }

  handlePlayState = () => {
    const { playing } = this.props;

    if (!this.audioElement.current) return;

    if (playing) {
      this.audioElement.current.currentTime = 0;
      this.audioElement.current.play().then(() =>
        this.setState({
          playReady: true,
        }));
    } else if (this.state.playReady) {
      this.audioElement.current.pause();
    }
  }

  renderToggleButton = () => {
    if (!this.props.playing) {
      return (
        <Button
          lightBg={true}
          onClick={e => this.handlePlay(e)}
        >
          <Play />
        </Button>
      );
    }

    return (
      <Button
        playing={true}
        onClick={this.handlePause}
      >
        <Pause />
      </Button>
    );
  }

  handleOnLoad(e) {
    const time = e.target.duration;
    const minutes = Math.floor(time / 60);
    const seconds = parseInt(time - minutes * 60);
    console.log({ time })
    this.setState({
      duration: minutes + ":" + seconds
    })
  }
  render() {
    const { track, active } = this.props;
    const { title, uid, src } = track;

    this.handlePlayState();

    const classes = classNames(
      'TrackRow',
      {
        'TrackRow--active': active,
        'TrackRow--inactive': !active,
      },
    );
    return (
      <div className={classes}>
        <div className="TrackRow__content">
          <span title={title} className='TrackRow__content--name'>{title}</span>
          <p className="TrackRow__duration">{this.state.duration}</p>
        </div>
        <div className="TrackRow__button-wrapper">
          {this.renderToggleButton()}
          <Button
            onClick={this.toggleSelect}
          >
            {active ? 'Unselect' : 'Select'}
          </Button>
        </div>
        <audio
          id={"audio-" + uid}
          onLoadedMetadata={this.handleOnLoad.bind(this)}
          loop={true}
          ref={this.audioElement}
        >
          <source src={src} type="audio/mp3" />
        </audio>
      </div >
    );
  }
}

TrackRow.propTypes = propTypes;
TrackRow.defaultProps = defaultProps;

export default TrackRow;
