import { DndProvider } from "react-dnd";
import Backend from 'react-dnd-html5-backend';
import VideoPreviewContainer from '@containers/VideoPreviewContainer';
import { useEffect, useState } from "react";

export default function RenderEngine() {
    // eslint-disable-next-line no-unused-vars
    const [frame, setFrame] = useState(0)
    useEffect(() => {
        //@ts-ignore
        window.engine = {}
        //@ts-ignore
        window.engine.seek = setFrame
    }, [])

    return (
        <DndProvider backend={Backend}>
            <div id="render-video-wrapper"
                style={{ width: "100%", height: "100%" }}>
                <VideoPreviewContainer />
            </div>
        </DndProvider>
    )
}