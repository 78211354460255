import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ToolTip from '../ToolTip';
import './NavigationItem.scss';

const NavigationItem = (props) => {
  const {
    label,
    icon,
    active,
    onClick,
    name,
    disabled,
    onSidebarAdjusters,
  } = props;

  const classes = classNames(
    'NavigationItem',
    {
      'NavigationItem--selected': active,
      'NavigationItem--disabled': disabled,
    },
  );

  const handleTabToggle = () => {
    onSidebarAdjusters('');
    if (disabled) return;
    onClick(name);
  };

  return (
    <ToolTip message={'Please select video first'} place="right" disable={!disabled}>
      <div className={classes} onClick={handleTabToggle}>
        <div className="NavigationItem__icon">{icon}</div>
        <span className="NavigationItem__label">{label}</span>
      </div>
    </ToolTip>
  );
};

NavigationItem.defaultProps = {
  active: false,
};

NavigationItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onSidebarAdjusters: PropTypes.func,
  name: PropTypes.string,
  icon: PropTypes.element,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default NavigationItem;
