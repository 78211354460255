export const askForFile = (accept) => {
    return new Promise((resolve, reject) => {
        try {
            let inputEl = document.createElement('input')
            inputEl.type = 'file'
            inputEl.accept = accept;
            inputEl.onchange = (e) => resolve(e.target.files)
            document.body.appendChild(inputEl)
            setTimeout(() => {
                inputEl.click()
                setTimeout(() => {
                    inputEl.remove()
                }, 3000)
            })
        } catch (e) {
            reject()
        }
    })
}